import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {getCurrentUser} from '../firebaseConfigs';
import {selectorLoggedUser} from "../store/user/user.selector";

export const RequireAuth =  ({children}) => {
    //const dispatch = useDispatch();
   
    //const token = localStorage.getItem('token');
    //const state =useSelector(selectorLoggedUser);
    const state =useSelector(selectorLoggedUser);
    const navigate = useNavigate();

    useEffect(() =>  {
        //let user = getCurrentUser();
       // console.log(user.toJSON());
        if (JSON.stringify(state) === '{}') {
            navigate("/login")
        }
        // else if(JSON.stringify(state) === '{}') {
        //     //let my = JSON.parse(user);
        //     //my['token'] = token;
        //     //dispatch(loginUserAction(my));
        // }
    })

    return (<div>{children}</div>)


}