//import webDev from '../graphic/web-developer.svg';
import '../App.css';
//import { useTranslation } from 'react-i18next';
//import {Card, CardBody} from "@nextui-org/react";
import {Header, ExsampleData, Stepper, Footer} from '../components/hero'


export const HomePage = () => {
  ///  const { t, i18n } = useTranslation(); className="App-header"

    return(
        <div>
            <header >
                <Header></Header>
                <Stepper></Stepper>
                <ExsampleData></ExsampleData>
                <Footer></Footer>
            </header>
        </div>
    )
}