
import { Switch } from "@nextui-org/react";
import React from "react";
import {useTheme} from "next-themes";
import { useEffect, useState } from "react";

import { MoonIcon } from "../graphic/icon/MoonIncon";
import { SunIcon } from "../graphic/icon/SunIcon";


export default function SwitchDarkMode(){
    const [mounted, setMounted] = useState(false)
    const { theme, setTheme } = useTheme()
    
    
    useEffect(() => {
        setMounted(true)
      }, [])
    
    
    if(!mounted) return null
    //setTheme('light')
    //setTheme('dark')
    return (
        <Switch
            onValueChange={(isSelected) => isSelected ?  setTheme('dark') : setTheme('light') }
          //defaultSelected
          size="lg"
          color="secondary"
          thumbIcon={({ isSelected, className }) =>
            isSelected ? (
              <SunIcon className={className} />
            ) : (
              <MoonIcon className={className} />
            )
          }
        >
        </Switch>
      );
}