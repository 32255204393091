import React from 'react';
import {selectorLoggedUser} from "../store/user/user.selector";
import {useSelector} from "react-redux";

export const NoAuthSection =  ({children}) => {
    let state =useSelector(selectorLoggedUser);

    if(!state.uid) {
        //console.log(state);
        return (<template> {children}</template>)
    }
    else {
        return (<template></template>)
    }

   
}