// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
// import { use } from "i18next";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCElXpH-eLgSrAFddHayu-DQR2jC2vWS5g",
  authDomain: "apicomuni.firebaseapp.com",
  projectId: "apicomuni",
  storageBucket: "apicomuni.appspot.com",
  messagingSenderId: "638926099105",
  appId: "1:638926099105:web:c21b333cb622d27aa7d6e0",
  measurementId: "G-ETNR0ND0KF"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const auth = getAuth();


export const onAuthState = async () => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          return uid;
        } else {
            return undefined;
        }
    });
} 

export const getCurrentUser = () => {
    const user = auth.currentUser;
    return user;
}

export const singUpUserCredential = async (email, password) => {
    try {
        return await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            let user = {
                uid : userCredential.user.uid,
                email : userCredential.user.email,
            };
            //console.log(user);
            return user;
        })
        .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            console.log(error);
            return undefined;
        });
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}


export const singInUserCredential = async (email, password) => {
    try {
       return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            let user = {
                uid : userCredential.user.uid,
                email : userCredential.user.email,
            };
            //console.log(user);
            return user;
        })
        .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            console.log(error);
            return error;
        });
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}


export const resetMailPassword = async (email) => {
    try {
        return sendPasswordResetEmail(auth, email)
        .then(() => {
            return true;
        })
        .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            console.log(error);
            return error;
        });
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}


export const singOut = async () => {
    try {
        return signOut(auth).then(() => {
            return true;
          }).catch((error) => {
            console.log(error);
            return false;
          });
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}
