
import { useTranslation } from 'react-i18next';
import {   Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination, Snippet } from "@nextui-org/react";
import {capitalize} from "../utils/utils";
import {PlusIcon} from "../graphic/icon/PlusIcons";
import {VerticalDotsIcon} from "../graphic/icon/VerticalDotsIcons";
import {SearchIcon} from "../graphic/icon/SearchIcons";
import {ChevronDownIcon} from "../graphic/icon/ChevronDownIcons";
import { useState, useMemo, useCallback, useEffect  } from 'react';
import {useNavigate} from "react-router";
import {singUpUserCredential, getCurrentUser} from '../firebaseConfigs';
import { users } from "../utils/data";
import axios from 'axios';
import {selectorLoggedUser} from "../store/user/user.selector";
import {useSelector} from "react-redux";

const statusOptions = [
    {name: "Free", uid: "Free"},
    {name: "PlanFull", uid: "paused"},
    {name: "PlanMedium", uid: "PlanMedium"},
    {name: "PlanBase", uid: "PlanBase"},
    {name: "Admin", uid: "Admin"},
    {name: "Blocked", uid: "Blocked"},
  ];

const columns = [
    {name: "uuid", uid: "uuid", sortable: true},
    {name: "token", uid: "token", sortable: true},
    {name: "state", uid: "state", sortable: true},
    {name: "userKey", uid: "userKey", sortable: true},
    {name: "email", uid: "email"},
    {name: "updatedAt", uid: "updatedAt"},
  ];
  

const statusColorMap = {
    Free: "success",
    PlanFull: "success",
    PlanMedium: "secondary",
    PlanBase: "default",
    Admin : "warning",
    Blocked : "danger"
  };

const INITIAL_VISIBLE_COLUMNS = ["uuid", "token", "state", "active", "userKey", "updatedAt"];
//const INITIAL_VISIBLE_COLUMNS = ["name", "role", "status", "actions"];
export const TokenManage = () => {
    const [req, setReq] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [sortDescriptor, setSortDescriptor] = useState({
      column: "updatedAt",
      direction: "ascending",
    });
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const state =useSelector(selectorLoggedUser);
    const getTokens = async () => {
        if(!req) {
           if(!state) {
                navigate('/login');
           }
           else{
                let tokensFromApi = await axios.get(`https://comuni.alessiopellizzaro.it/api/apiKeys?userKey=${state.uid}`).then((res) => {
                    return res.data.token;
                });
                setTokens(tokensFromApi);
                setReq(true);
           }
        }
    }

    const addToken = async () => {
        let obj = {
            "email" : state.email,
            "userKey" : state.uid
        };

        await axios.post('https://comuni.alessiopellizzaro.it/api/apiKeys', obj).then((res) => {
            setReq(false);
            return res.data.token;
        });

    }

    useEffect( () => {
        getTokens();
    }, [navigate, req , setReq, tokens, setTokens, getTokens])

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = useMemo(() => {
      if (visibleColumns === "all") return columns;
  
      return columns.filter((column) => Array.from(visibleColumns).includes(column.name));
    }, [visibleColumns]);
  

    const filteredItems = useMemo(() => {
        let filteredUsers = [...tokens];
    
        if (hasSearchFilter) {
          filteredUsers = filteredUsers.filter((token) =>
          token.email.toLowerCase().includes(filterValue.toLowerCase()),
          );
        }
        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
          filteredUsers = filteredUsers.filter((token) =>
            Array.from(statusFilter).includes(token.state),
          );
        }
    
        return filteredUsers;
      }, [tokens, filterValue, statusFilter]);



    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
      const start = (page - 1) * rowsPerPage;
      const end = start + rowsPerPage;
  
      return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);
  
    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
          const first = a[sortDescriptor.column];
          const second = b[sortDescriptor.column];
          const cmp = first < second ? -1 : first > second ? 1 : 0;
    
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
      }, [sortDescriptor, items]);

      const renderCell = useCallback((token, columnKey) => {
        const cellValue = token[columnKey];
    
        switch (columnKey) {
          case "uuid":
            return (
              <User
                //avatarProps={{radius: "sm", src: token.avatar}}
                //description={cellValue}
                name={cellValue}
              >
                {token.uid}
              </User>
            );
          case "token":
            return (
              <div className="flex flex-col">
                <Snippet codeString={cellValue} variant="solid" color="primary"> Copy token </Snippet>
              </div>
            );
          case "state":
                let translateState = '';

                switch(cellValue){
                    case 4:
                        translateState="Free";
                        break;
                    case 5:
                        translateState="Admin";
                        break;
                    case 3:
                        translateState="PlanFull";
                        break;
                    case 2:
                        translateState="PlanMedium";
                        break;
                    case 1:
                        translateState="PlanBase";
                        break;
                    case 0:
                        translateState="Blocked";
                        break;
                }


            return (
              <Chip className="capitalize" color={statusColorMap[translateState]} size="lg" variant="dot">
                {translateState}
              </Chip>
            );
          case "actions":
            return (
              <div className="relative flex justify-end items-center gap-2">
                <Dropdown>
                  <DropdownTrigger>
                    <Button isIconOnly size="sm" variant="light">
                      <VerticalDotsIcon className="text-default-300" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem>View</DropdownItem>
                    <DropdownItem>Edit</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            );
          default:
            return cellValue;
        }
      }, []);

      const onNextPage = useCallback(() => {
        if (page < pages) {
          setPage(page + 1);
        }
      }, [page, pages]);
    
      const onPreviousPage = useCallback(() => {
        if (page > 1) {
          setPage(page - 1);
        }
      }, [page]);
    
      const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
      }, []);

      const onSearchChange = useCallback((value) => {
        if (value) {
          setFilterValue(value);
          setPage(1);
        } else {
          setFilterValue("");
        }
      }, []);
    
      const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
      },[])

      const topContent = useMemo(() => {
        return (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between gap-3 items-end">
              {/* <Input
                isClearable
                className="w-full sm:max-w-[44%]"
                placeholder="Search by email..."
                startContent={<SearchIcon />}
                value={filterValue}
                onClear={() => onClear()}
                onValueChange={onSearchChange}
              /> */}
              <div className="flex gap-3">
                {/* <Dropdown>
                  <DropdownTrigger className="hidden sm:flex">
                    <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                      Status
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    disallowEmptySelection
                    aria-label="Table Columns"
                    closeOnSelect={false}
                    selectedKeys={statusFilter}
                    selectionMode="multiple"
                    onSelectionChange={setStatusFilter}
                  >
                    {statusOptions.map((status) => (
                      <DropdownItem key={status.uid} className="capitalize">
                        {capitalize(status.name)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown> */}
                <Dropdown>
                  <DropdownTrigger className="hidden sm:flex">
                    <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                      Columns
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    disallowEmptySelection
                    aria-label="Table Columns"
                    closeOnSelect={false}
                    selectedKeys={visibleColumns}
                    selectionMode="multiple"
                    onSelectionChange={setVisibleColumns}
                  >
                    {columns.map((column) => (
                      <DropdownItem key={column.uid} className="capitalize">
                        {capitalize(column.name)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <Button color="primary" endContent={<PlusIcon />} onClick={addToken}>
                  Add New
                </Button>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-default-400 text-small">Total {tokens.length} token</span>
              <label className="flex items-center text-default-400 text-small">
                Rows per page:
                <select
                  className="bg-transparent outline-none text-default-400 text-small"
                  onChange={onRowsPerPageChange}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </label>
            </div>
          </div>
        );
      }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        users.length,
        onSearchChange,
        hasSearchFilter,
      ]);

      const bottomContent = useMemo(() => {
        return (
          <div className="py-2 px-2 flex justify-between items-center">
            <span className="w-[30%] text-small text-default-400">
              {selectedKeys === "all"
                ? "All items selected"
                : `${selectedKeys.size} of ${filteredItems.length} selected`}
            </span>
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={pages}
              onChange={setPage}
            />
            <div className="hidden sm:flex w-[30%] justify-end gap-2">
              <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                Previous
              </Button>
              <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                Next
              </Button>
            </div>
          </div>
        );
      }, [selectedKeys, items.length, page, pages, hasSearchFilter]);


    return (
        <div className='flex place-content-center justify-center content-center'>
            <div className='container p-5 '>
                <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{
                    wrapper: "max-h-[382px]",
                }}
                selectedKeys={selectedKeys}
                selectionMode="multiple"
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
                >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                    <TableColumn
                        key={column.uid}
                        align={column.uid === "actions" ? "center" : "start"}
                        allowsSorting={column.sortable}
                    >
                        {column.name}
                    </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"No Token found"} items={sortedItems}>
                    {(item) => (
                    <TableRow key={item.uuid}>
                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                    </TableRow>
                    )}
                </TableBody>
                </Table>
            </div>  
        </div>
    )
}