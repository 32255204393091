
import { useState  } from 'react';
import {useNavigate} from "react-router";
import { useTranslation } from 'react-i18next';
import {  Button, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Checkbox} from "@nextui-org/react";
import {singUpUserCredential, getCurrentUser} from '../firebaseConfigs';
import {EyeFilledIcon} from "../graphic/icon/EyeFilledIcon";
import {EyeSlashFilledIcon} from "../graphic/icon/EyeSlashFilledIcon";
import {useDispatch} from "react-redux";
import { loginUserAction} from "../store/user/user.actions";

export const RegisterForm = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);
    const {isOpen, onOpen, onClose ,onOpenChange} = useDisclosure();
    const [modalTitle, setModalTile] = useState("");
    const [modalText, setModalText] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sendRegister = async () => {
        try {
            if(password === confirmPassword){
                let getUser = await singUpUserCredential(email, password).then((user) => {return user});
                 //await getCurrentUser();
                if(getUser) {
                    dispatch(loginUserAction(getUser));
                    navigate("/")
                }
                else{
                    setModalTile(t("modal.error"));
                    setModalText("Error in Login");
                    onOpen();
                }
            }
        }
        catch (e) {
            setModalTile(t("modal.error"));
            setModalText(e.message);
            onOpen();
           // console.log(e);
        }
    }


    return (
        <div>
            <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    Comuni by Alessio Pellizzaro    
                </a>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Create Account
                        </h1>
                        <form className="space-y-4 md:space-y-6" action="#">
                            <div  data-te-input-wrapper-init>
                                <Input label="Email" type="email" placeholder={t('contact.plEmail')} size='lg' value={email} onValueChange={setEmail}/>
                            </div>
                            <div data-te-input-wrapper-init>
                                <Input label="Password" placeholder="Enter your password" size='lg' value={password} onValueChange={setPassword}
                                      endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                          {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                          ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                          )}
                                        </button>
                                      }
                                      type={isVisible ? "text" : "password"}
                                />
                                
                            </div>
                            <div data-te-input-wrapper-init>
                                <Input label="Confirm Password" placeholder="Confirm your password" size='lg' value={confirmPassword} onValueChange={setConfirmPassword}
                                      endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                          {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                          ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                          )}
                                        </button>
                                      }
                                      type={isVisible ? "text" : "password"}
                                />
                                
                            </div>
                            <div className="flex items-start">
                                <div className="ml-3 text-sm">
                                <Checkbox isSelected={isSelected} onValueChange={setIsSelected}>
                                I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="https://www.iubenda.com/privacy-policy/26387695/legal">Terms and Conditions</a>
                                </Checkbox>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                            </div>
                            <Button color="primary" className='w-full' variant="shadow" onClick={() => sendRegister()}>
                                Sign up
                            </Button>  
                            
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                You have an account? <a href="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            </section>
            <Modal 
                backdrop="blur" 
                isOpen={isOpen} 
                onOpenChange={onOpenChange}
                radius="2xl"
            >
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">{modalTitle}</ModalHeader>
                    <ModalBody>
                        <p> 
                        {modalText}
                        </p>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="solid" onPress={onClose}>
                        {t('btn.close')}
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
}

