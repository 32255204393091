import '../App.css';
import {TokenManage} from '../components/tokenManage';


export const TokenPage = () => {
    return(
        <div>
            <header >
                <TokenManage />
            </header>
        </div>
    )
}