import React, { useState } from 'react'
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle,  NavbarMenu, NavbarMenuItem ,Link} from "@nextui-org/react";
import {useNavigate} from "react-router";
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import {Button} from "@nextui-org/react";

import SwitchDarkMode from './SwitchDarkMode';

import {useTheme} from "next-themes";


import { AuthSection } from './AuthSection';
import { NoAuthSection } from './NoAuthSection';

function NavBarSite({children}) {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { theme, setTheme } = useTheme();
    // const changeLanguage = (lng) => { 
    //     i18n.changeLanguage(lng);
    // }
    const { t } = useTranslation();
    const navigate = useNavigate();
    const menuItems = [
        "home",
        "contact",
      ];

    return (
        <main className='font-sans' >
            <Navbar onMenuOpenChange={setIsMenuOpen} shouldHideOnScroll>
                <NavbarContent>
                    <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    // className="xl:hidden"
                    />
                    <NavbarBrand>
                    <p className="font-bold font-mono text-inherit" >Comuni</p>
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                    <Button variant="light" onClick={() => {
                            navigate('/')
                        }} > #Home </Button>
                    {/* <Link color="foreground" href="/">
                        {t("nav.about")}
                    </Link> */}
                    </NavbarItem>
                    <NavbarItem>
                        <Button variant="light" onClick={() => {
                            navigate('/token')
                        }} > #Api </Button>
                    {/* <Link color="foreground" href="/token" >
                       #Api
                    </Link> */}
                    </NavbarItem>
                </NavbarContent>
                <NavbarContent justify="end">
                        {/* <AuthenticatedTemplate>
                        <NavbarItem className="hidden lg:flex">
                            <SignOutButton />
                        </NavbarItem>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                        <NavbarItem className="hidden lg:flex">
                            <SignInButton />
                        </NavbarItem>
                        </UnauthenticatedTemplate> */}
                            <NavbarItem className="">
                                <SwitchDarkMode/>
                            </NavbarItem>
                        <NoAuthSection>
                            <NavbarItem className="">
                                <Button color="primary" variant="shadow" onClick={() => navigate('/login')}> Login </Button>  
                            </NavbarItem>
                            <NavbarItem className="">
                                <Button color="primary" variant="shadow" onClick={() => navigate('/register')}> SignUp </Button>  
                            </NavbarItem>
                        </NoAuthSection>
                        <AuthSection>
                            <NavbarItem className="">
                                <Button color="secondary" variant="shadow" onClick={() => console.log('logout')}> Logout </Button>  
                            </NavbarItem>
                        </AuthSection>
                       
                </NavbarContent>
                
                <NavbarMenu>
                    <NavbarMenuItem>
                        <Button className="w-full" variant="light" onClick={() => {
                                navigate('/')
                            }} > #Home 
                            
                        </Button>
                        <Button className="w-full" variant="light" onClick={() => {
                                navigate('/token')
                            }} > #Api
                        </Button>
                        <Button className="w-full" variant="light" onClick={() => {
                                navigate('/login')
                            }} > #Login
                        </Button>
                        <Button className="w-full" variant="light" onClick={() => {
                                navigate('/register')
                            }} > #Register
                        </Button>
                        {/* <Button className="w-full" variant="light" onClick={() => {
                                navigate('/Log')
                            }} > #Register
                        </Button> */}
                    
                    </NavbarMenuItem>
            
                </NavbarMenu>
                </Navbar>
              <div >
                   {children}
                </div>  
        </main>
      
    );

}
//className="dark text-foreground bg-background"
export default NavBarSite;