import { Route, Routes } from "react-router";
import NavBarSite from "./components/navBar";
import { RequireAuth } from "./components/RequireAuth";
import { HomePage } from "./page/homepPage";
import { LoginPage } from "./page/loginPage";
import { RegisterPage } from "./page/registerPage";
import { TokenPage } from "./page/tokenPage";
import './App.css';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path='/' element={ <NavBarSite> <HomePage /> </NavBarSite> } />
          <Route path='/token' element={ <NavBarSite> <RequireAuth> <TokenPage /> </RequireAuth> </NavBarSite> } />
          <Route path='/login' element={ <LoginPage /> } />
          <Route path='/register' element={ <RegisterPage /> } />
    </Routes>
    </div>
  );
}

export default App;
