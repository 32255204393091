import '../App.css';
import {LoginForm} from '../components/loginForm';


export const LoginPage = () => {
    return(
        <div>
            <header >
                <LoginForm />
            </header>
        </div>
    )
}