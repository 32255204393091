import '../App.css';
import {RegisterForm} from '../components/registerForm';


export const RegisterPage = () => {
    return(
        <div>
            <header >
                <RegisterForm />
            </header>
        </div>
    )
}